import useTheme from 'hooks/useTheme'
import { Heading, Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'

const StatCardContent: React.FC<
  React.PropsWithChildren<{ headingText: string; bodyText: string; highlightColor: string, numberLastWork?: number }>
> = ({ headingText, bodyText, highlightColor, numberLastWork = 1 }) => {
  const { isDark } = useTheme()
  const { isMobile, isTablet } = useMatchBreakpoints()
  const isSmallerScreen = isMobile || isTablet
  const split = headingText.split(' ')
  let lastWord = ''
  for(let i = 0; i < numberLastWork; i++) {
    lastWord = `${split.pop()} ${lastWord}`
  }
  const remainingWords = split.slice(0, split.length).join(' ')

  return (
    <Flex
      minHeight={[null, null, null, null]}
      minWidth="232px"
      width="fit-content"
      flexDirection="column"
      justifyContent="flex-end"
      mt={['30px', '30px', '30px', '64px']}
    >
      {isSmallerScreen && remainingWords.length > 13 ? (
        <Heading color={isDark ? 'white' : '#122549'} scale="lg">
          {remainingWords}
        </Heading>
      ) : (
        <Heading color={isDark ? 'white' : '#122549'} scale="lg">
          {remainingWords}
        </Heading>
      )}
      <Heading color={highlightColor} scale="lg" mb="24px">
        {lastWord}
      </Heading>
      <Text color={isDark ? 'textSubtle' : '#263A4C'}>{bodyText}</Text>
    </Flex>
  )
}

export default StatCardContent
