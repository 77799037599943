import useTheme from 'hooks/useTheme'
import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Pioneering Decentralized Trade:'),
  headingSubText: t('Experience ZedDex’s Phenomenal Growth!'),
  colorHeadingText: useTheme().isDark ? 'white' : '#122549',
  firstColorText: useTheme().isDark ? 'white' : '#122549',
  colorHeadingSubText: '#1FC7D4',
  bodyText: t('Trade any thing. No registration, no hassle'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  // secondaryButton: {
  //   to: 'https://docs.pancakeswap.finance/',
  //   text: t('Learn'),
  //   external: true,
  // },
  images: {
    path: '/images/home/home4/',
    attributes: [{ src: 'image1', alt: 'image' }],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Earn DexHub'),
  colorHeadingText: useTheme().isDark ? 'white' : '#122549',
  firstColorText: '#1FC7D4',
  bodyText: t('Invest wisely with crypto earn smart money'),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  // secondaryButton: {
  //   to: 'https://docs.pancakeswap.finance/products/yield-farming',
  //   text: t('Learn'),
  //   external: true,
  // },
  images: {
    path: '/images/home/home5/',
    attributes: [{ src: 'image1', alt: t('image') }],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('ZED'),
  colorHeadingText: useTheme().isDark ? "white" : '#122549',
  firstColorText: '#1FC7D4',
  bodyText: t(
    'Discover the power of ZED crypto, shaping a new era of financial freedom.<br/>ZED Token is Brain of ZedDex ecosystem. Trade it, Buy & Sell it, Farm it, Send it, Stake it.<br/>Step into the future of finance with ZED crypto where possibilities are limitless.',
  ),
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0x5B0F21f60116b9463210C00C7A1A6A46E26BFA33&chainId=83872',
    text: t('Buy ZedDex'),
    external: false,
  },
  // secondaryButton: {
  //   to: 'https://docs.pancakeswap.finance/tokenomics/cake',
  //   text: t('Learn'),
  //   external: true,
  // },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'image1', alt: t('image') },
    ],
  },
})
