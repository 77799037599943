import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M26 22C26 22.2652 25.8946 22.5196 25.7071 22.7071C25.5196 22.8946 25.2652 23 25 23H1C0.734784 23 0.48043 22.8946 0.292893 22.7071C0.105357 22.5196 0 22.2652 0 22C0 21.7348 0.105357 21.4804 0.292893 21.2929C0.48043 21.1054 0.734784 21 1 21H2V13C2 12.7348 2.10536 12.4804 2.29289 12.2929C2.48043 12.1054 2.73478 12 3 12H6C6.26522 12 6.51957 12.1054 6.70711 12.2929C6.89464 12.4804 7 12.7348 7 13V21H9V7C9 6.73478 9.10536 6.48043 9.29289 6.29289C9.48043 6.10536 9.73478 6 10 6H14C14.2652 6 14.5196 6.10536 14.7071 6.29289C14.8946 6.48043 15 6.73478 15 7V21H17V1C17 0.734784 17.1054 0.48043 17.2929 0.292893C17.4804 0.105357 17.7348 0 18 0H23C23.2652 0 23.5196 0.105357 23.7071 0.292893C23.8946 0.48043 24 0.734784 24 1V21H25C25.2652 21 25.5196 21.1054 25.7071 21.2929C25.8946 21.4804 26 21.7348 26 22Z" />
    </Svg>
  );
};

export default Icon;
