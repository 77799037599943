import { useEffect, useState } from 'react'
import { Heading, Flex, Text, Skeleton, ChartIcon, CommunityIcon, SwapIcon, SwapHome } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { formatBigInt, formatLocalisedCompactNumber, formatNumber } from '@pancakeswap/utils/formatBalance'
import useSWRImmutable from 'swr/immutable'
import { ChainId } from '@pancakeswap/sdk'
import { publicClient } from 'utils/wagmi'
import IconCard, { IconCardData } from '../IconCard'
import StatCardContent from './StatCardContent'
import GradientLogo from '../GradientLogoSvg'

const Stats = () => {
  const { t } = useTranslation()
  const { theme, isDark } = useTheme()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t('And those users are now entrusting the platform with over $%tvl% in funds.', { tvl: tvlString })
  const [entrusting, inFunds] = tvlText.split(tvlString)

  const [totalAssets, setTotalAssets] = useState('0')
  const [totalTVL, setTotalTVL] = useState('0')
  const [vol24h, setVol24] = useState('0')
  const [vol24hMarket, setVol24Market] = useState('0')
  const [vol24hZedcex, setVol24Zedcex] = useState('0')

  useEffect(() => {
    async function getData() {
      const dataAssets = await(await fetch('https://follow.ciaoaibot.com/openapi/robots_api/getZedcexExchangeData')).json()
      let assetsVolume = 0
      if(dataAssets.data) {
        setTotalAssets(formatNumber(dataAssets.data.total_assets))
        setVol24Zedcex(formatNumber(dataAssets.data.spot_volume_usd))
        assetsVolume = dataAssets.data.spot_volume_usd;
      }

      const dataVol24 = await(await fetch('https://follow.ciaoaibot.com/openapi/robots_api/getZedxionExchangeData')).json()
      let volume24h = 0;
      if (dataVol24.data) {
        setVol24(formatNumber(dataAssets.data.spot_volume_usd))
        volume24h = dataAssets.data.spot_volume_usd;
      }

      setVol24Market(formatNumber(assetsVolume + volume24h))

      const client = publicClient({ chainId: ChainId.ZEDX })
      const totalStakedToken = await client.readContract({
        abi: StakeAbi,
        address: '0xb265133bF92b489622e2Fb0022e6B7E4B2F55d73',
        functionName: 'totalStakedToken',
      })
      if (totalStakedToken) {
        setTotalTVL(formatBigInt(totalStakedToken))
      } 
    }
    getData()
  }, [])

  

  const UsersCardData: IconCardData = {
    icon: <CommunityIcon color="#1FC7D4" width="30px" />,
    background: isDark ? "#27262c" : '#ffffff',
  }
  
  const TradesCardData: IconCardData = {
    icon: <SwapHome color="#1BC5DC" width="28px" />,
    background: isDark ? "#27262c" : '#ffffff',
  }
  
  const StakedCardData: IconCardData = {
    icon: <ChartIcon color="#DC781B" width="26px" />,
    background: isDark ? "#27262c" : '#ffffff',
  }

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <GradientLogo height="104px" width="104px" mb="24px" />
      <Heading textAlign="center" scale="xl" color={isDark ? 'white' : '#25356C'} mb={30}>
        {t('Top-rated')}
      </Heading>
      <Heading textAlign="center" scale="xl" color={isDark ? 'white' : '#25356C'} mb={30}>
        {t('Trading DexHub')}
      </Heading>
      <Text textAlign="center" color={isDark ? 'white' : '#25356C'} mb={'20px'} maxWidth={645}>
        {t(
          'ZedDex Powered by ZEDXION, a poneering NeoBanking Blockchain boasts a staggering Billions Trading Volume (24h) and holds over Billions in Financial Reserves (Total Assets)',
        )}
      </Text>
      {/* <Flex flexWrap="wrap">
        <Text display="inline" textAlign="center" color={isDark ? "white" : "#25356C"} mb="20px">
          {entrusting}
          <>{tvl ? <>{tvlString}</> : <Skeleton display="inline-block" height={16} width={70} mt="2px" />}</>
          {inFunds}
        </Text>
      </Flex> */}

      <Text textAlign="center" color={isDark ? 'white' : '#263A4C'} bold mb="32px">
        {t('Will you join them?')}
      </Text>

      <Flex maxWidth="100%" flexDirection={['column', null, null, 'row']} flexWrap="wrap" style={{ gap: '16px' }}>
        <IconCard {...UsersCardData} style={{ flex: '1 1 auto' }}>
          <StatCardContent
            headingText={t('%users% users', { users })}
            // headingText={t('%users% users', { users })}
            bodyText={t('in the last 30 days')}
            highlightColor={'#AD1CFC'}
          />
        </IconCard>
        {/* <IconCard {...TradesCardData}>
          <StatCardContent
            headingText={t('%trades% trades', { trades })}
            bodyText={t('made in the last 30 days')}
            highlightColor={'#1FC7D4'}
          />
        </IconCard>
        <IconCard {...StakedCardData}>
          <StatCardContent
            headingText={t('%tvl% staked', { tvl: tvlString })}
            bodyText={t('Total Value Locked')}
            highlightColor={'#DC781B'}
          />
        </IconCard> */}
        <IconCard {...TradesCardData} style={{ flex: '1 1 auto' }}>
          <StatCardContent
            headingText={t('Financial Reserves (Total Assets)')}
            bodyText={t('$%totalAssets%', { totalAssets })}
            highlightColor={'#1FC7D4'}
            numberLastWork={2}
          />
        </IconCard>
        <IconCard {...StakedCardData} style={{ flex: '1 1 auto' }}>
          <StatCardContent
            headingText={t('Total Value Locked (TVL)')}
            bodyText={t('$%totalTVL%', { totalTVL })}
            highlightColor={'#DC781B'}
          />
        </IconCard>
        <IconCard {...StakedCardData} style={{ flex: '1 1 auto' }}>
          <StatCardContent
            headingText={t('ZEDXION Exchange (Vol24h)')}
            bodyText={t('$%vol24h%', { vol24h })}
            highlightColor={'#dc1b84'}
          />
        </IconCard>
        <IconCard {...StakedCardData} style={{ flex: '1 1 auto' }}>
          <StatCardContent
            headingText={t('Total Market (EXs Vol24)')}
            bodyText={t('$%vol24hMarket%', { vol24hMarket })}
            highlightColor={'#1baedc'}
            numberLastWork={2}
          />
        </IconCard>
        <IconCard {...StakedCardData} style={{ flex: '1 1 auto' }}>
          <StatCardContent
            headingText={t('ZedCex (Vol24h)')}
            bodyText={t('$%vol24hZedcex%', { vol24hZedcex })}
            highlightColor={'#db1bdc'}
          />
        </IconCard>
      </Flex>
    </Flex>
  )
}

const StakeAbi = [
  {
    inputs: [],
    name: 'totalStakedToken',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const

export default Stats
