import { Flex, Text, Button, Link, NextLinkFromReactRouter as RouterLink, OpenNewIcon } from '@pancakeswap/uikit'
import CompositeImage, { CompositeImageProps } from '../CompositeImage'
import ColoredWordHeading from '../ColoredWordHeading'

interface SalesSectionButton {
  to: string
  text: string
  external: boolean
}

export interface SalesSectionProps {
  headingText: string
  headingSubText?: string
  bodyText: string
  reverse: boolean
  primaryButton: SalesSectionButton
  secondaryButton?: SalesSectionButton
  images: CompositeImageProps
  colorHeadingText?: string
  firstColorText?: string
  colorHeadingSubText?: string
}

const SalesSection: React.FC<React.PropsWithChildren<SalesSectionProps>> = (props) => {
  const { headingText, headingSubText, colorHeadingText, firstColorText, colorHeadingSubText, bodyText, reverse, primaryButton, secondaryButton, images } = props
  const bodyTextArray = bodyText.split('<br/>')
  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={['column-reverse', null, null, reverse ? 'row-reverse' : 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="center"
      >
        <Flex
          flexDirection="column"
          flex="1"
          ml={[null, null, null, reverse && '64px']}
          mr={[null, null, null, !reverse && '64px']}
          alignSelf={['flex-start', null, null, 'center']}
        >
          <ColoredWordHeading
            text={headingText}
            color={colorHeadingText ? colorHeadingText : 'text'}
            firstColorText={firstColorText ? firstColorText : 'text'}
            mb={headingSubText ? 0 : 24}
          />
          {headingSubText && (
            <ColoredWordHeading
              text={headingSubText}
              color={colorHeadingSubText ? colorHeadingSubText : 'text'}
              firstColorText={colorHeadingSubText ? colorHeadingSubText : 'text'}
              mb={24}
            />
          )}
          {bodyTextArray.map((text) => (
            <Text color={colorHeadingText ? colorHeadingText : 'text'} mb="24px" key={text.replace(' ', '')}>
              {text}
            </Text>
          ))}
          <Flex>
            <Button mr="16px">
              {primaryButton.external ? (
                <Link external href={primaryButton.to}>
                  <Text color="card" bold fontSize="16px">
                    {primaryButton.text}
                  </Text>
                </Link>
              ) : (
                <RouterLink to={primaryButton.to}>
                  <Text color="card" bold fontSize="16px">
                    {primaryButton.text}
                  </Text>
                </RouterLink>
              )}
            </Button>
            {secondaryButton ? (
              <>
                {secondaryButton.external ? (
                  <Link external href={secondaryButton.to}>
                    {secondaryButton.text}
                    <OpenNewIcon color="primary" ml="4px" />
                  </Link>
                ) : (
                  <RouterLink to={secondaryButton.to}>{secondaryButton.text}</RouterLink>
                )}
              </>
            ) : null}
          </Flex>
        </Flex>
        <Flex
          height={['192px', null, null, '100%']}
          width={['192px', null, null, '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
        >
          <CompositeImage {...images} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SalesSection
