import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.8314 8.17139C19.4983 6.38065 18.6825 4.71488 17.4719 3.35391C16.2614 1.99293 14.7021 0.988441 12.9624 0.448858C11.2227 -0.0907256 9.36865 -0.144904 7.60041 0.29217C5.83217 0.729244 4.2169 1.64097 2.92893 2.92893C1.64097 4.2169 0.729244 5.83217 0.29217 7.60041C-0.144904 9.36865 -0.0907256 11.2227 0.448858 12.9624C0.988441 14.7021 1.99293 16.2614 3.35391 17.4719C4.71488 18.6825 6.38065 19.4983 8.17139 19.8314C8.50446 21.6221 9.32031 23.2879 10.5309 24.6489C11.7414 26.0098 13.3007 27.0143 15.0404 27.5539C16.7801 28.0935 18.6341 28.1477 20.4024 27.7106C22.1706 27.2735 23.7859 26.3618 25.0738 25.0738C26.3618 23.7859 27.2735 22.1706 27.7106 20.4024C28.1477 18.6341 28.0935 16.7801 27.5539 15.0404C27.0143 13.3007 26.0098 11.7414 24.6489 10.5309C23.2879 9.32031 21.6221 8.50446 19.8314 8.17139ZM10.0026 18.0026C8.42039 18.0026 6.87367 17.5334 5.55808 16.6544C4.24248 15.7753 3.2171 14.5259 2.6116 13.0641C2.0061 11.6023 1.84767 9.99376 2.15636 8.44192C2.46504 6.89007 3.22696 5.4646 4.34578 4.34578C5.4646 3.22696 6.89007 2.46504 8.44192 2.15636C9.99376 1.84767 11.6023 2.0061 13.0641 2.6116C14.5259 3.2171 15.7753 4.24248 16.6544 5.55808C17.5334 6.87367 18.0026 8.42039 18.0026 10.0026C18.0003 12.1237 17.1567 14.1571 15.6569 15.6569C14.1571 17.1567 12.1237 18.0003 10.0026 18.0026Z"/>
    </Svg>
  );
};

export default Icon;
