import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 33 33" {...props}>
      <path d="M23.4767 6.00294C23.7427 5.62644 23.8706 5.16959 23.8385 4.70968C23.8065 4.24977 23.6165 3.81506 23.3008 3.47909L21.1759 1.22026C20.8124 0.833918 20.3104 0.607775 19.7802 0.591584C19.25 0.575393 18.7351 0.770481 18.3488 1.13393L0.868101 17.5786C0.481755 17.942 0.255611 18.4441 0.239421 18.9743C0.22323 19.5044 0.418318 20.0193 0.781768 20.4057L2.90672 22.6645C3.2228 23.0001 3.64511 23.2163 4.10221 23.2763C4.5593 23.3363 5.02311 23.2366 5.41514 22.994C6.00313 22.6332 6.69752 22.4861 7.38133 22.5774C8.06514 22.6687 8.69658 22.9929 9.16928 23.4954C9.64198 23.9979 9.92705 24.6479 9.97648 25.336C10.0259 26.0241 9.83668 26.7083 9.44065 27.2732C9.17409 27.6503 9.04629 28.1082 9.07897 28.5689C9.11165 29.0296 9.30278 29.4648 9.61991 29.8006L11.7449 32.0595C12.1083 32.4458 12.6104 32.672 13.1405 32.6881C13.6707 32.7043 14.1856 32.5092 14.572 32.1458L32.0526 15.7011C32.439 15.3377 32.6651 14.8357 32.6813 14.3055C32.6975 13.7753 32.5024 13.2604 32.139 12.874L30.014 10.6152C29.6979 10.2796 29.2756 10.0635 28.8185 10.0034C28.3614 9.94339 27.8976 10.0431 27.5056 10.2857C26.9176 10.6465 26.2232 10.7937 25.5394 10.7023C24.8556 10.611 24.2242 10.2868 23.7515 9.78432C23.2788 9.28184 22.9937 8.63179 22.9443 7.94369C22.8948 7.25558 23.0841 6.57146 23.4801 6.00658L23.4767 6.00294ZM11.0775 28.4312C11.7426 27.4895 12.0619 26.347 11.9813 25.197C11.9007 24.047 11.4252 22.9602 10.6353 22.1205C9.84542 21.2809 8.78963 20.74 7.64669 20.5894C6.50376 20.4388 5.34389 20.6877 4.36345 21.2941L2.23849 19.0353L7.33701 14.2389L18.3001 25.8927L13.2016 30.6891L11.0775 28.4312Z" />
    </Svg>
  );
};

export default Icon;
